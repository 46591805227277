export const PATHS = {
	JS_ASYNC: '/static/js/async',
	WEBFONT_LOADER: '//ajax.googleapis.com/ajax/libs/webfont/1/webfont.js'
};

export const FONTS = {
    google: { families: ['Fira+Sans:300,400,400i,600,700:latin'] }
};

export const TOGGLES = {
	SELECTOR:{
		GLOBAL: '.js-toggle',
		LOCAL: '.js-toggle-local'
	},
	OPTIONS: {
		LOCAL: {
			local: true
		}
	}
};

export const GALLERY = {
	SELECTOR: {
		LINK: '.js-gallery__trigger-link',
		SCROLLABLE_LINK: '.js-gallery__trigger-link--scrollable',
		DATA: '.js-gallery__trigger-data'
	}
};