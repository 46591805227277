import { PATHS} from './constants';
import { FONTS} from './constants';
// import 'es6-promise/auto';
import Promise from 'promise-polyfill';
//import 'lazysizes' from 'lazysizes';
import Toggle from './require/toggle';
import './require/outliner';
import AsyncHelper from './require/async-helper';
import ModalGallery from './require/modal-gallery';
import Load from 'storm-load';

const onInit = [
	Toggle,
	ModalGallery,
	() => {
		Load(PATHS.WEBFONT_LOADER)
			.then(() => {
				global.WebFont.load(FONTS);
			});
	},
	() => {
		if(!document.querySelector('.js-slides')) return;
		Load('/static/js/async/storm-slides.standalone.min.js')
		.then(() => {
			StormSlides.init('.js-slides', {
				autoPlay: true,
				slideDuration: 6
			});
		});
	},

];

const onLoad = [];
{
	window.Promise = window.Promise ? window.Promise : Promise;

	if(!Object.assign || !('classList' in document.createElement('_'))) 
		Load(`${PATHS.JS_ASYNC}/polyfills.min.js`)
			.then(() => { 
				onInit.forEach(fn => fn());
			});
	else onInit.forEach(fn => fn());

	onLoad.length && window.addEventListener('load', [].forEach.bind(onLoad, fn => fn()));

	//if ('serviceWorker' in navigator) navigator.serviceWorker.register('/sw.js');
}