import { GALLERY, PATHS }  from '../../constants';
import Load from 'storm-load';

const fromData = () => {
	let galleryTrigger = document.querySelector(GALLERY.SELECTOR.DATA),
		gallery = StormModalGallery.init(JSON.parse(galleryTrigger.getAttribute('data-gallery')));
	
	galleryTrigger.addEventListener('click', () => {
		gallery.open.call(gallery, 0);
	});
};

const fromLink = () => { StormModalGallery.init(GALLERY.SELECTOR.LINK) };

const fromLinkScrollable = () => { StormModalGallery.init(GALLERY.SELECTOR.SCROLLABLE_LINK, { scrollable: true }); };

const fromLinkSingle = () => { StormModalGallery.init(GALLERY.SELECTOR.SINGLE, { single: true })};

export default () => {
	if((!document.querySelector(GALLERY.SELECTOR.SCROLLABLE_LINK) && !document.querySelector(GALLERY.SELECTOR.DATA) && !document.querySelector(GALLERY.SELECTOR.LINK))) return;

	Load(`${PATHS.JS_ASYNC}/storm-modal-gallery.min.js`)
		.then(() => {
			document.querySelector(GALLERY.SELECTOR.DATA) && fromData();
			document.querySelector(GALLERY.SELECTOR.LINK) && fromLink();
			document.querySelector(GALLERY.SELECTOR.SCROLLABLE_LINK) && fromLinkScrollable();
			document.querySelector(GALLERY.SELECTOR.SINGLE) && fromLinkSingle();
		});
};